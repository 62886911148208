<template>
  <div>
    <Table
      label="Interest"
      :actions="actions"
      :items="$store.getters['interest/tableData']"
      :detail="$store.getters['interest/detailData']"
      :fields="$store.getters['interest/tableFields']"
      :loading="$store.state.interest.loadingTable"
      :filter="$store.state.interest.tableFilter"
      :sort="$store.state.interest.tableSort"
      :sort-options="sortOptions"
      @changeFields="changeFields"
      @resetFields="resetFields"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @loadDetail="loadDetail"
      @resetDetail="resetDetail"
    />

    <!-- Delete User confirmation modal -->
    <b-modal
      v-model="deleteModalVisible"
      title="Delete User"
      ok-title="Yes, delete"
      cancel-variant="white"
      @ok="deleteUser"
    >
      Are you sure want to delete this data?. This action cannot be undone.
    </b-modal>
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      deleteModalVisible: false,
      selectedData: '',

      // Sort
      sortOptions: [
        {
          key: 'sort', direction: 'asc', label: 'Sort', default: true,
        },
        {
          key: 'createdAt', direction: 'desc', label: 'Newest',
        },
        { key: 'createdAt', direction: 'asc', label: 'Oldest' },
      ],

      actions: [
        'view',
        'create',
        'update',
        {
          type: 'other',
          children: [
            {
              label: 'Delete',
              event: this.confirmDelete,
              visibility: {
                callback: () => this.canAccess('manage', 'Interest'),
              },
            },
          ],
        },
      ],
    }
  },
  methods: {
    loadData() {
      return this.$store.dispatch('interest/getTableData')
    },
    filterData(val) {
      this.$store.commit('interest/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('interest/SET_TABLE_SORT', val)
    },
    loadDetail(id) {
      return this.$store.dispatch('interest/getDetail', id)
    },
    resetDetail() {
      this.$store.commit('interest/SET_DETAIL_DATA', undefined)
    },
    changeFields(val) {
      this.$store.commit('interest/SET_TABLE_FIELDS', val)
    },
    resetFields() {
      this.$store.commit('interest/RESET_TABLE_FIELDS')
    },
    confirmDelete(data) {
      this.selectedData = data.item
      this.deleteModalVisible = true
    },
    deleteUser() {
      this.$store.dispatch('interest/delete', this.selectedData.id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'The interest has been deleted!',
              variant: 'success',
            },
          })

          this.deleteModalVisible = false
          this.loadData()
        })
    },
  },
}
</script>
